<template>
  <div>
    <!-- 新增banner元件 -->
    <v-menu
      offset-y
      v-if="!disabled"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          color="primary"
          small
          v-on="on"
          :loading="loading"
        >
          {{'banner.action.create_item'| t}}
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          dense
          @click="openPhotoManager"
        >
          <v-list-item-title>
            {{`photo_manager`|t}}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          dense
          v-for="type in itemTypes"
          @click="addNewItem(type)"
          :key="type"
        >
          <v-list-item-title>
            {{`banner.type.${type}`|t}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <invisible-photo-uploader
      ref="photoUploader"
      multiple
      type="banner"
      @appendPhoto="appendPhoto"
      @uploadAllCompleted="uploadAllPhotoCompleted"
      @onLoadingChange="onPhotoUploaderLoadingChange"
    ></invisible-photo-uploader>

    <div class="elevation-4 px-4 py-8 my-4">
      <div v-if="items.length == 0">
        {{'data.empty'| t}}
      </div>

      <v-draggable
        class="d-flex my-4 overflow-auto"
        handle=".banner-item-draggable"
        @end="sortEnd"
        :forceFallback="true"
        v-model="items"
      >
        <component
          v-for="(item, index) in items"
          @updateItem="updateItem"
          @refresh="$emit('refresh')"
          :disabled="disabled"
          :key="`banner-item-${index}`"
          :is="getBannerItemComponent(item.instance_type)"
          :item="item"
          :index="index"
        >
          <!-- 操作 -->
          <template v-slot:actions v-if="!disabled">
            <div class="banner-item-actions">
              <!-- draggable -->
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn
                    v-on="on"
                    class="banner-item-draggable"
                    icon
                    color="orange"
                    small
                  >
                    <v-icon>fa fa-bars</v-icon>
                  </v-btn>
                </template>
                <div>{{'action.move'| t}}</div>
              </v-tooltip>

              <!-- 移除 -->
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn
                    v-on="on"
                    icon
                    color="red"
                    small
                    @click="removeItem(index)"
                  >
                    <v-icon>fa fa-trash</v-icon>
                  </v-btn>
                </template>
                <div>{{'action.remove'| t}}</div>
              </v-tooltip>
            </div>
          </template>
        </component>
      </v-draggable>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    banner: {
      type: Object,
      default: null,
    },
    updateBannerRequest: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    itemImageSize: 150,
    items: [],
    loading: false,
  }),
  methods: {
    openPhotoManager() {
      this.$photoSelector({
        max: 10,
        multiple: true,
        applyCallback: async (data) => {
          for(const item of data) {
            await this.appendPhoto(item.photo)
            await this.updateBannerRequest()
          }
        }
      })
    },
    setVideo() {
      this.$videoSelector({
        applyCallback: async data => {
          this.items.push({
            banner_id: this.banner.id,
            instance: data,
            instance_type: 'video',
          })
          await this.$nextTick()
          this.updateBannerRequest()
        },
      })
    },
    removeItem(index) {
      this.$apopup.base({
        title: this.$t('remove.confirm'),
        applyCallback: async () => {
          const bannerItemId = this.items[index].id
          this.$delete(this.items, index)
          await this.$nextTick()
          await this.removeBannerItemRequest(bannerItemId)
          this.updateBannerRequest()
        }
      })
    },
    async removeBannerItemRequest(bannerItemId) {
      this.$store.dispatch('loading/active')
      this.$store.dispatch('loading/progress')
      try {
        await this.$api.collection.bannerApi.deleteBannerItem(bannerItemId)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$store.dispatch('loading/close')
        this.$store.dispatch('loading/closeProgress')
      }
    },
    // 新增類型由此擴充
    addNewItem(type) {
      if(type === this.$bannerConstants.BANNER_ITEM_TYPE_PHOTO) {
        this.$refs.photoUploader.openFileBrowser()
        return
      }

      if(type === this.$bannerConstants.BANNER_ITEM_TYPE_VIDEO) {
        this.setVideo()
        return
      }
    },
    onPhotoUploaderLoadingChange(status) {
      this.loading = status
    },
    async appendPhoto(photo) {
      this.loading = true
      const params = { photo }
      try {
        const result = await this.$api.collection.bannerPhotoApi.create(params)
        this.items.push({
          banner_id: this.banner.id,
          instance: result,
          instance_type: 'photo',
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async uploadAllPhotoCompleted() {
      this.loading = true
      await this.$nextTick()
      await this.updateBannerRequest()
      this.loading = false
    },
    getBannerItemComponent(type) {
      const componentName = `banner-item-${type}`
      if(this.$options.components[componentName]) {
        return this.$options.components[componentName]
      }
      return null
    },
    sortEnd() {
      this.updateBannerRequest()
    },
    async updateItem(data) {
      this.$set(this.banner.items, data.index, {
        instance: data.instance,
        instance_type: data.type,
      })
      await this.$nextTick()
      this.updateBannerRequest()
    },
  },
  computed: {
    itemTypes() {
      return this.$bannerItemTypes
    },
  },
  components: {
    'banner-item-photo': () => import('components/bannerEditor/bannerItem/bannerItemPhoto.vue'),
    'banner-item-video': () => import('components/bannerEditor/bannerItem/bannerItemVideo.vue'),
  },
  watch: {
    banner: {
      immediate: true,
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.banner.items, this.items)) return
        this.items = window.eagleLodash.cloneDeep(this.banner.items)
      },
    },
    items: {
      deep: true,
      handler() {
        this.$emit('updateItems', window.eagleLodash.cloneDeep(this.items))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass">
.banner-item
  position: relative
  .banner-item-actions
    position: absolute
    top: 0
    right: 0
</style>
